import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';

function initialize() {
  if (!isShopifyEmbedded()) {
    const host =
      process.env.REACT_APP_API_URL ?? `https://${window.location.host}`;
    // eslint-disable-next-line no-return-assign
    return (window.location.href = `${host}/api/auth/install${window.location.search}`);
  }

  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
  const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
  if (
    process.env.NODE_ENV === 'production' &&
    SENTRY_DSN &&
    SENTRY_ENVIRONMENT &&
    SENTRY_RELEASE
  ) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release: SENTRY_RELEASE,
      integrations: [new BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

initialize();
