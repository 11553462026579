import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useCallback } from 'react';

export function useAppBridgeRedirect() {
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const executeAdminRedirect = useCallback(
    (path: string, newContext = false) => {
      redirect.dispatch(Redirect.Action.ADMIN_PATH, {
        path,
        newContext,
      });
    },
    [redirect],
  );

  const executeAppRedirect = useCallback(
    (path: string) => {
      redirect.dispatch(Redirect.Action.APP, path);
    },
    [redirect],
  );

  return {
    executeAdminRedirect,
    executeAppRedirect,
  };
}
