import {
  Card,
  Stack,
  Button,
  ResourceList,
  ResourceItem,
  Thumbnail,
  TextStyle,
  TextField as PolarisTextField,
  RadioButton,
  FormLayout,
  TextContainer,
} from '@shopify/polaris';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { ImageMajor } from '@shopify/polaris-icons';
import { Collection as ResourcePickerCollection } from '@shopify/app-bridge/actions/ResourcePicker';
import { collectionPickerSelectionToFormValues } from '../utils';
import { FormValues } from './MainForm';

export function DiscountEligibility() {
  const [collectionPickerOpen, setCollectionPickerOpen] = useState(false);
  const [collectionPickerQuery, setCollectionPickerQuery] = useState('');

  const formik = useFormikContext<FormValues>();

  const handleChangeRadio = useCallback(
    (value: boolean, id: string) => {
      formik.setValues((prev) => ({
        ...prev,
        discountEligibilityType:
          id === 'AllProductsDiscounted'
            ? 'AllProducts'
            : 'SelectedCollections',
      }));
    },
    [formik],
  );

  const handleChangeCollectionsRadio = useCallback(() => {
    setCollectionPickerOpen(true);
  }, []);

  const handleOpenCollectionPicker = useCallback((initialQuery?: string) => {
    if (initialQuery) {
      setCollectionPickerQuery(initialQuery);
    }
    setCollectionPickerOpen(true);
  }, []);

  const handleCloseCollectionPicker = useCallback(() => {
    formik.setValues((prev) => ({
      ...prev,
      discountEligibilityType: prev.discountCollections.length
        ? 'SelectedCollections'
        : 'AllProducts',
    }));

    setCollectionPickerOpen(false);
    setCollectionPickerQuery('');
  }, [formik]);

  const handleSelection = useCallback(
    ({ selection }: { selection: ResourcePickerCollection[] }) => {
      formik.setValues((prev) => ({
        ...prev,
        discountEligibilityType: selection.length
          ? 'SelectedCollections'
          : 'AllProducts',
        discountCollections: collectionPickerSelectionToFormValues(selection),
      }));
      handleCloseCollectionPicker();
    },
    [formik, handleCloseCollectionPicker],
  );

  const handleRemoveCollection = useCallback(
    (id: string) => {
      formik.setValues((prev) => {
        const filtered = prev.discountCollections.filter(
          (collection) => collection.id !== id,
        );

        return {
          ...prev,
          discountEligibilityType: filtered.length
            ? 'SelectedCollections'
            : 'AllProducts',
          discountCollections: filtered,
        };
      });
    },
    [formik],
  );

  return (
    <>
      <ResourcePicker
        resourceType="Collection"
        open={collectionPickerOpen}
        selectMultiple
        onSelection={handleSelection}
        onCancel={handleCloseCollectionPicker}
        initialQuery={collectionPickerQuery}
        initialSelectionIds={formik.values.discountCollections.map(
          (collection) => ({ id: collection.id }),
        )}
      />
      <Card.Section>
        <TextContainer>
          <p>
            Choose which products the delivered discount code will be applicable
            to.
          </p>
          <p>Discount applies to</p>
        </TextContainer>
        <FormLayout>
          <Stack vertical spacing="extraTight">
            <RadioButton
              label="All products"
              checked={formik.values.discountEligibilityType === 'AllProducts'}
              name="discountEligibilityType"
              id="AllProductsDiscounted"
              onChange={handleChangeRadio}
            />
            <RadioButton
              label="Specific collections"
              name="discountEligibilityType"
              checked={
                formik.values.discountEligibilityType === 'SelectedCollections'
              }
              id="SelectedCollectionsDiscounted"
              onChange={handleChangeCollectionsRadio}
            />
          </Stack>
          {formik.values.discountEligibilityType === 'SelectedCollections' && (
            <PolarisTextField
              label="Browse Collections"
              labelHidden
              autoComplete="off"
              name="dummyInput"
              placeholder="Browse collections"
              error={formik.errors.discountCollections as string} // TODO type this properly
              connectedRight={
                <Button onClick={() => handleOpenCollectionPicker()}>
                  Browse
                </Button>
              }
              onChange={(value) => {
                handleOpenCollectionPicker(value);
              }}
            />
          )}
        </FormLayout>
      </Card.Section>

      {formik.values.discountEligibilityType === 'SelectedCollections' &&
        formik.values.discountCollections.length > 0 && (
          <ResourceList
            items={formik.values.discountCollections}
            renderItem={(collection) => {
              const shortcutActions = [
                {
                  content: 'Remove',
                  onClick: () => handleRemoveCollection(collection.id),
                },
              ];
              const thumbnail = collection.image?.src ?? ImageMajor;
              const alt = collection.image?.altText ?? collection.title;

              return (
                <ResourceItem
                  id={collection.id}
                  onClick={() => undefined}
                  shortcutActions={shortcutActions}
                  media={
                    <Thumbnail size="small" source={thumbnail} alt={alt} />
                  }
                  verticalAlignment="center"
                >
                  <TextStyle variation="strong">{collection.title}</TextStyle>
                </ResourceItem>
              );
            }}
          />
        )}
    </>
  );
}
