import { EmptyState } from '@shopify/polaris';
import { useThemesQuery } from '../queries';
import { connectionToNodes } from '../utils';
import WelcomeContent from './WelcomeContent';
import WelcomeSkeleton from './WelcomeSkeleton';

export default function Welcome() {
  const { data, isLoading, error } = useThemesQuery({ first: 20 });

  if (error) {
    return (
      <EmptyState
        heading="Oops! Something went wrong."
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      />
    );
  }

  if (!data || isLoading) {
    return <WelcomeSkeleton />;
  }

  return <WelcomeContent themes={connectionToNodes(data.themes)} />;
}
