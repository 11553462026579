import { useClientRouting } from '@shopify/app-bridge-react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Settings from './Settings';
import Welcome from './Welcome';

function Router() {
  const navigate = useNavigate();

  useClientRouting({ replace: (path) => navigate(path, { replace: true }) });

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="settings" element={<Settings />} />
      <Route path="welcome" element={<Welcome />} />
    </Routes>
  );
}

export default Router;
