import { Card, DisplayText, TextStyle } from '@shopify/polaris';
import styles from './DashboardCard.module.css';

interface DashboardCardProps {
  title: string;
  statistic: string;
  subtext?: string;
}

function DashboardCard(props: DashboardCardProps) {
  const { title, statistic, subtext } = props;

  return (
    <Card
      title={
        <TextStyle variation="strong">
          <TextStyle variation="subdued">{title}</TextStyle>
        </TextStyle>
      }
      sectioned
    >
      <DisplayText size="large">{statistic}</DisplayText>
      <hr className={styles.horizontalLine} />
      <TextStyle variation="subdued">{subtext ?? <>&nbsp;</>}</TextStyle>
    </Card>
  );
}

export default DashboardCard;
