import {
  Layout,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
  Page,
} from '@shopify/polaris';

export default function MainFormSkeleton() {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={1} />
            </TextContainer>
          </Card>
        </Layout.Section>

        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="small" />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="small" />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
