import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContextualSaveBar as AppBridgeCSBA } from '@shopify/app-bridge/actions';
import { ContextualSaveBarProps } from '@shopify/polaris';
import { useAppBridge } from '@shopify/app-bridge-react';

// LOGIC FLOW:
// 1. User updates the form, making it dirty
// 2. Our custom ContextualSaveBar is rendered, with SaveAction and discardAction passed as props that contain the callbacks (to either submit or reset the form) and loading/disabled states (depending on if the form is being submitted)
// 3. ContextualSaveBarOptions is initialized with defaults first, then overridden by the info passed through the props
// 4. saveBar state is initialized using the Shopify App Bridge ContextualSaveBar Action (requires app context and contextualSaveBarOptions generated in Step 3)
// 5. Define handleDiscard and handleSubmit handlers using the callbacks that were passed through the props
// 6. Subscribe to the DISCARD and SAVE actions, and pass in the respective handlers defined in Step 5.
// 7. When the component unmounts, dispatch the HIDE action and unsubscribe to DISCARD and SAVE

const DEFAULT_OPTIONS = {
  saveAction: {
    disabled: false,
    loading: false,
  },
  discardAction: {
    disabled: false,
    loading: false,
    discardConfirmationModal: false,
  },
};

export function ContextualSaveBar(props: ContextualSaveBarProps) {
  const { saveAction = {}, discardAction = {} } = props;

  const contextualSaveBarOptions = useMemo<ContextualSaveBarProps>(() => {
    return {
      saveAction: {
        ...DEFAULT_OPTIONS.saveAction,
        ...saveAction, // Override default settings with settings from props
        onAction: undefined, // Not part of the Shopify Contextual Save Bar API and needs to be removed when updating settings
      },
      discardAction: {
        ...DEFAULT_OPTIONS.discardAction,
        ...discardAction, // Override default settings with settings from props
        onAction: undefined, // Not part of the Shopify Contextual Save Bar API and needs to be removed when updating settings
      },
    };
  }, [saveAction, discardAction]);

  const app = useAppBridge();

  const [saveBar] = useState(() =>
    AppBridgeCSBA.create(app, contextualSaveBarOptions),
  );

  useEffect(() => {
    saveBar.set(contextualSaveBarOptions);
  }, [saveBar, contextualSaveBarOptions]);

  const handleDiscard = useCallback(() => {
    if (discardAction.onAction) {
      discardAction.onAction();
    }
  }, [discardAction]);

  const handleSubmit = useCallback(() => {
    if (saveAction.onAction) {
      saveAction.onAction();
    }
  }, [saveAction]);

  useEffect(() => {
    // .subscribe() method automatically subscribes when called and returns the method needed to unsubscribe
    const discardUnsubscribe = saveBar.subscribe(
      AppBridgeCSBA.Action.DISCARD,
      handleDiscard,
    );
    const saveUnsubscribe = saveBar.subscribe(
      AppBridgeCSBA.Action.SAVE,
      handleSubmit,
    );
    return () => {
      discardUnsubscribe();
      saveUnsubscribe();
    };
  }, [handleDiscard, handleSubmit, saveBar]);

  useEffect(
    // Shorthand syntax for cleanup function on component unmount
    () => () => {
      saveBar.dispatch(AppBridgeCSBA.Action.HIDE);
    },
    [saveBar],
  );

  return null;
}
