import { useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { SERVER_URL } from '../config';

export function useFetcher<TData, TVariables>(
  query: string,
): (variables?: TVariables) => Promise<TData> {
  const app = useAppBridge();

  return async (variables?: TVariables) => {
    const sessionToken = await getSessionToken(app);

    const res = await fetch(`${SERVER_URL}/api/graphql`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      // eslint-disable-next-line no-console
      console.error(message);

      throw new Error(message);
    }

    return json.data;
  };
}
