import {
  Button,
  ButtonProps,
  Icon,
  Link,
  Stack,
  TextContainer,
} from '@shopify/polaris';
import { QuestionMarkInverseMajor } from '@shopify/polaris-icons';
import styles from './PageActions.module.css';

interface PageActionsProps {
  button?: ButtonProps;
}

export default function PageActions(props: PageActionsProps) {
  const { button } = props;

  return (
    <div className={styles.pageActionsWrapper}>
      <Stack alignment="center" distribution="equalSpacing" spacing="tight">
        <Stack spacing="tight">
          <Icon source={QuestionMarkInverseMajor} color="base" />
          <TextContainer>
            Need assistance?{' '}
            <Link
              external
              monochrome
              url="https://together.satelcreative.com/pages/contact"
              accessibilityLabel="Support link"
            >
              Get in touch with our support team.
            </Link>
          </TextContainer>
        </Stack>
        {button && <Button {...button}>Save</Button>}
      </Stack>
    </div>
  );
}
