/* eslint-disable */
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { useFetcher } from './fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: any;
};

export type AllProducts = {
  __typename?: 'AllProducts';
  eligibleOnAllProducts: Scalars['Boolean'];
};

export type Analytics = {
  __typename?: 'Analytics';
  startingAt: Scalars['Datetime'];
  togetherOrders: Scalars['Int'];
  totalOrders: Scalars['Int'];
  /** @deprecated Use `togetherOrders`. */
  utfsOrders: Scalars['Int'];
};

export enum BillingStatus {
  /** Still within free plan */
  Free = 'FREE',
  /** Billing has not setup */
  Notset = 'NOTSET',
  /** Has been upgraded to paid plain */
  Plan = 'PLAN',
  /** Still within trial time */
  Trial = 'TRIAL'
}

export type Collection = {
  __typename?: 'Collection';
  image?: Maybe<Image>;
  legacyResourceId: Scalars['ID'];
  title: Scalars['String'];
};

export type EligibleProducts = AllProducts | SelectedCollections;

export type Image = {
  __typename?: 'Image';
  altText?: Maybe<Scalars['String']>;
  src: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  billingSetup?: Maybe<Scalars['String']>;
  /** Handle order processed from order processing app */
  orderProcess?: Maybe<Scalars['String']>;
  /** Handle order tagging from order processing app */
  orderTagging?: Maybe<Scalars['String']>;
  settingsUpdate: Settings;
  setupComplete?: Maybe<Scalars['String']>;
  themeIsCompatible: Scalars['Boolean'];
};


export type MutationOrderProcessArgs = {
  input: OrderProcessInput;
};


export type MutationOrderTaggingArgs = {
  input: OrderTaggingInput;
};


export type MutationSettingsUpdateArgs = {
  input?: InputMaybe<SettingsUpdateInput>;
};


export type MutationThemeIsCompatibleArgs = {
  id: Scalars['ID'];
};

/** Handle order Shopify call requested from order processing app */
export type OrderProcessInput = {
  /** The Shopify customer ID */
  customerId: Scalars['ID'];
  /** The customer email for this order */
  email?: InputMaybe<Scalars['String']>;
  /** Order ID */
  id: Scalars['ID'];
  /** Shopify order name */
  orderName: Scalars['String'];
  /** The customer phone for the order checkout */
  phone?: InputMaybe<Scalars['String']>;
};

export type OrderTaggingInput = {
  /** Order ID */
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  analytics: Analytics;
  health: Scalars['String'];
  /** Get the settings for the store */
  settings: Settings;
  /** Check the billing info of the store */
  shop: Shop;
  themes: ThemeConnection;
};


export type QueryThemesArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type SelectedCollections = {
  __typename?: 'SelectedCollections';
  collections?: Maybe<Array<Collection>>;
};

export type Settings = {
  __typename?: 'Settings';
  discountEligibility: EligibleProducts;
  percentDiscount: Scalars['Int'];
  productEligibility: EligibleProducts;
};

export type SettingsUpdateInput = {
  discountCollections?: InputMaybe<Array<Scalars['ID']>>;
  discountOnAllProducts: Scalars['Boolean'];
  eligibleCollections?: InputMaybe<Array<Scalars['ID']>>;
  eligibleOnAllProducts: Scalars['Boolean'];
  percentDiscount: Scalars['Int'];
};

export type Shop = {
  __typename?: 'Shop';
  /** Current billing status of this shop */
  billingStatus: BillingStatus;
  /** Indicates if this shop has finished setup */
  setupComplete: Scalars['Boolean'];
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated Field no longer supported */
  published: Scalars['Boolean'];
  role: ThemeRole;
};

export type ThemeConnection = {
  __typename?: 'ThemeConnection';
  edges: Array<ThemeNode>;
};

export type ThemeNode = {
  __typename?: 'ThemeNode';
  node: Theme;
};

export enum ThemeRole {
  Demo = 'DEMO',
  Development = 'DEVELOPMENT',
  Main = 'MAIN',
  Unpublished = 'UNPUBLISHED'
}

export type AnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsQuery = { __typename?: 'Query', analytics: { __typename?: 'Analytics', totalOrders: number, togetherOrders: number } };

export type SetupBillingMutationVariables = Exact<{ [key: string]: never; }>;


export type SetupBillingMutation = { __typename?: 'Mutation', billingSetup?: string | null };

type EligibleProductsForm_AllProducts_Fragment = { __typename: 'AllProducts', eligibleOnAllProducts: boolean };

type EligibleProductsForm_SelectedCollections_Fragment = { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null };

export type EligibleProductsFormFragment = EligibleProductsForm_AllProducts_Fragment | EligibleProductsForm_SelectedCollections_Fragment;

export type SettingsFormFragment = { __typename?: 'Settings', percentDiscount: number, productEligibility: { __typename: 'AllProducts', eligibleOnAllProducts: boolean } | { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null }, discountEligibility: { __typename: 'AllProducts', eligibleOnAllProducts: boolean } | { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null } };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings: { __typename?: 'Settings', percentDiscount: number, productEligibility: { __typename: 'AllProducts', eligibleOnAllProducts: boolean } | { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null }, discountEligibility: { __typename: 'AllProducts', eligibleOnAllProducts: boolean } | { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null } } };

export type SettingsUpdateMutationVariables = Exact<{
  input: SettingsUpdateInput;
}>;


export type SettingsUpdateMutation = { __typename?: 'Mutation', settingsUpdate: { __typename?: 'Settings', percentDiscount: number, productEligibility: { __typename: 'AllProducts', eligibleOnAllProducts: boolean } | { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null }, discountEligibility: { __typename: 'AllProducts', eligibleOnAllProducts: boolean } | { __typename: 'SelectedCollections', collections?: Array<{ __typename?: 'Collection', legacyResourceId: string, title: string, image?: { __typename?: 'Image', src: string, altText?: string | null } | null }> | null } } };

export type SetupCompleteMutationVariables = Exact<{ [key: string]: never; }>;


export type SetupCompleteMutation = { __typename?: 'Mutation', setupComplete?: string | null };

export type ShopQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopQuery = { __typename?: 'Query', shop: { __typename?: 'Shop', setupComplete: boolean, billingStatus: BillingStatus } };

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = { __typename?: 'Query', health: string };

export type ThemeFragment = { __typename?: 'Theme', id: string, name: string, role: ThemeRole };

export type ThemesQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type ThemesQuery = { __typename?: 'Query', themes: { __typename?: 'ThemeConnection', edges: Array<{ __typename?: 'ThemeNode', node: { __typename?: 'Theme', id: string, name: string, role: ThemeRole } }> } };

export type ThemeIsCompatibleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ThemeIsCompatibleMutation = { __typename?: 'Mutation', themeIsCompatible: boolean };

export const EligibleProductsFormFragmentDoc = `
    fragment EligibleProductsForm on EligibleProducts {
  __typename
  ... on SelectedCollections {
    collections {
      legacyResourceId
      image {
        src
        altText
      }
      title
    }
  }
  ... on AllProducts {
    eligibleOnAllProducts
  }
}
    `;
export const SettingsFormFragmentDoc = `
    fragment SettingsForm on Settings {
  percentDiscount
  productEligibility {
    ...EligibleProductsForm
  }
  discountEligibility {
    ...EligibleProductsForm
  }
}
    ${EligibleProductsFormFragmentDoc}`;
export const ThemeFragmentDoc = `
    fragment Theme on Theme {
  id
  name
  role
}
    `;
export const AnalyticsDocument = `
    query Analytics {
  analytics {
    totalOrders
    togetherOrders
  }
}
    `;
export const useAnalyticsQuery = <
      TData = AnalyticsQuery,
      TError = Error
    >(
      variables?: AnalyticsQueryVariables,
      options?: UseQueryOptions<AnalyticsQuery, TError, TData>
    ) =>
    useQuery<AnalyticsQuery, TError, TData>(
      variables === undefined ? ['Analytics'] : ['Analytics', variables],
      useFetcher<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument).bind(null, variables),
      options
    );
useAnalyticsQuery.document = AnalyticsDocument;


useAnalyticsQuery.getKey = (variables?: AnalyticsQueryVariables) => variables === undefined ? ['Analytics'] : ['Analytics', variables];
;

export const SetupBillingDocument = `
    mutation SetupBilling {
  billingSetup
}
    `;
export const useSetupBillingMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SetupBillingMutation, TError, SetupBillingMutationVariables, TContext>) =>
    useMutation<SetupBillingMutation, TError, SetupBillingMutationVariables, TContext>(
      ['SetupBilling'],
      useFetcher<SetupBillingMutation, SetupBillingMutationVariables>(SetupBillingDocument),
      options
    );
export const SettingsDocument = `
    query Settings {
  settings {
    ...SettingsForm
  }
}
    ${SettingsFormFragmentDoc}`;
export const useSettingsQuery = <
      TData = SettingsQuery,
      TError = Error
    >(
      variables?: SettingsQueryVariables,
      options?: UseQueryOptions<SettingsQuery, TError, TData>
    ) =>
    useQuery<SettingsQuery, TError, TData>(
      variables === undefined ? ['Settings'] : ['Settings', variables],
      useFetcher<SettingsQuery, SettingsQueryVariables>(SettingsDocument).bind(null, variables),
      options
    );
useSettingsQuery.document = SettingsDocument;


useSettingsQuery.getKey = (variables?: SettingsQueryVariables) => variables === undefined ? ['Settings'] : ['Settings', variables];
;

export const SettingsUpdateDocument = `
    mutation SettingsUpdate($input: SettingsUpdateInput!) {
  settingsUpdate(input: $input) {
    ...SettingsForm
  }
}
    ${SettingsFormFragmentDoc}`;
export const useSettingsUpdateMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SettingsUpdateMutation, TError, SettingsUpdateMutationVariables, TContext>) =>
    useMutation<SettingsUpdateMutation, TError, SettingsUpdateMutationVariables, TContext>(
      ['SettingsUpdate'],
      useFetcher<SettingsUpdateMutation, SettingsUpdateMutationVariables>(SettingsUpdateDocument),
      options
    );
export const SetupCompleteDocument = `
    mutation SetupComplete {
  setupComplete
}
    `;
export const useSetupCompleteMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SetupCompleteMutation, TError, SetupCompleteMutationVariables, TContext>) =>
    useMutation<SetupCompleteMutation, TError, SetupCompleteMutationVariables, TContext>(
      ['SetupComplete'],
      useFetcher<SetupCompleteMutation, SetupCompleteMutationVariables>(SetupCompleteDocument),
      options
    );
export const ShopDocument = `
    query Shop {
  shop {
    setupComplete
    billingStatus
  }
}
    `;
export const useShopQuery = <
      TData = ShopQuery,
      TError = Error
    >(
      variables?: ShopQueryVariables,
      options?: UseQueryOptions<ShopQuery, TError, TData>
    ) =>
    useQuery<ShopQuery, TError, TData>(
      variables === undefined ? ['Shop'] : ['Shop', variables],
      useFetcher<ShopQuery, ShopQueryVariables>(ShopDocument).bind(null, variables),
      options
    );
useShopQuery.document = ShopDocument;


useShopQuery.getKey = (variables?: ShopQueryVariables) => variables === undefined ? ['Shop'] : ['Shop', variables];
;

export const TestDocument = `
    query Test {
  health
}
    `;
export const useTestQuery = <
      TData = TestQuery,
      TError = Error
    >(
      variables?: TestQueryVariables,
      options?: UseQueryOptions<TestQuery, TError, TData>
    ) =>
    useQuery<TestQuery, TError, TData>(
      variables === undefined ? ['Test'] : ['Test', variables],
      useFetcher<TestQuery, TestQueryVariables>(TestDocument).bind(null, variables),
      options
    );
useTestQuery.document = TestDocument;


useTestQuery.getKey = (variables?: TestQueryVariables) => variables === undefined ? ['Test'] : ['Test', variables];
;

export const ThemesDocument = `
    query Themes($first: Int!) {
  themes(first: $first) {
    edges {
      node {
        ...Theme
      }
    }
  }
}
    ${ThemeFragmentDoc}`;
export const useThemesQuery = <
      TData = ThemesQuery,
      TError = Error
    >(
      variables: ThemesQueryVariables,
      options?: UseQueryOptions<ThemesQuery, TError, TData>
    ) =>
    useQuery<ThemesQuery, TError, TData>(
      ['Themes', variables],
      useFetcher<ThemesQuery, ThemesQueryVariables>(ThemesDocument).bind(null, variables),
      options
    );
useThemesQuery.document = ThemesDocument;


useThemesQuery.getKey = (variables: ThemesQueryVariables) => ['Themes', variables];
;

export const ThemeIsCompatibleDocument = `
    mutation ThemeIsCompatible($id: ID!) {
  themeIsCompatible(id: $id)
}
    `;
export const useThemeIsCompatibleMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ThemeIsCompatibleMutation, TError, ThemeIsCompatibleMutationVariables, TContext>) =>
    useMutation<ThemeIsCompatibleMutation, TError, ThemeIsCompatibleMutationVariables, TContext>(
      ['ThemeIsCompatible'],
      useFetcher<ThemeIsCompatibleMutation, ThemeIsCompatibleMutationVariables>(ThemeIsCompatibleDocument),
      options
    );