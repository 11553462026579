import { AppProvider } from '@shopify/polaris';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, ProviderProps } from '@shopify/app-bridge-react';

import en from '@shopify/polaris/locales/en.json';

import '@shopify/polaris/build/esm/styles.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './components/Router';

function retrieveHost() {
  const url = new URL(window.location.href);
  return url.searchParams.get('host');
}

const config: ProviderProps['config'] = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  host: retrieveHost()!,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider i18n={en}>
        <BrowserRouter>
          <Provider config={config}>
            <Router />
          </Provider>
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
