import {
  Layout,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
  Page,
} from '@shopify/polaris';

export default function WelcomeSkeleton() {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <br />
          <TextContainer>
            <SkeletonDisplayText size="large" />
            <SkeletonBodyText lines={4} />
          </TextContainer>
          <br />
        </Layout.Section>

        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="small" />}
          description={<SkeletonBodyText lines={4} />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title={<SkeletonDisplayText size="small" />}
          description={<SkeletonBodyText lines={5} />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
