import {
  Card,
  Stack,
  Button,
  ResourceList,
  ResourceItem,
  Thumbnail,
  TextStyle,
  TextField as PolarisTextField,
  RadioButton,
  FormLayout,
} from '@shopify/polaris';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { ImageMajor } from '@shopify/polaris-icons';
import { Collection as ResourcePickerCollection } from '@shopify/app-bridge/actions/ResourcePicker';
import { collectionPickerSelectionToFormValues } from '../utils';
import { EligibilityType, FormValues } from './MainForm';

export function ProductEligibility() {
  const [collectionPickerOpen, setCollectionPickerOpen] = useState(false);
  const [collectionPickerQuery, setCollectionPickerQuery] = useState('');

  const formik = useFormikContext<FormValues>();

  const handleChangeRadio = useCallback(
    (value: boolean, id: EligibilityType) => {
      formik.setValues((prev) => ({
        ...prev,
        eligibilityType: id,
      }));
    },
    [formik],
  );

  const handleChangeCollectionsRadio = useCallback(() => {
    setCollectionPickerOpen(true);
  }, []);

  const handleOpenCollectionPicker = useCallback((initialQuery?: string) => {
    if (initialQuery) {
      setCollectionPickerQuery(initialQuery);
    }
    setCollectionPickerOpen(true);
  }, []);

  const handleCloseCollectionPicker = useCallback(() => {
    formik.setValues((prev) => ({
      ...prev,
      eligibilityType: prev.eligibleCollections.length
        ? 'SelectedCollections'
        : 'AllProducts',
    }));

    setCollectionPickerOpen(false);
    setCollectionPickerQuery('');
  }, [formik]);

  const handleSelection = useCallback(
    ({ selection }: { selection: ResourcePickerCollection[] }) => {
      formik.setValues((prev) => ({
        ...prev,
        eligibilityType: selection.length
          ? 'SelectedCollections'
          : 'AllProducts',
        eligibleCollections: collectionPickerSelectionToFormValues(selection),
      }));
      handleCloseCollectionPicker();
    },
    [formik, handleCloseCollectionPicker],
  );

  const handleRemoveCollection = useCallback(
    (id: string) => {
      formik.setValues((prev) => {
        const filtered = prev.eligibleCollections.filter(
          (collection) => collection.id !== id,
        );

        return {
          ...prev,
          eligibilityType: filtered.length
            ? 'SelectedCollections'
            : 'AllProducts',
          eligibleCollections: filtered,
        };
      });
    },
    [formik],
  );

  return (
    <>
      <ResourcePicker
        resourceType="Collection"
        open={collectionPickerOpen}
        selectMultiple
        onSelection={handleSelection}
        onCancel={handleCloseCollectionPicker}
        initialQuery={collectionPickerQuery}
        initialSelectionIds={formik.values.eligibleCollections.map(
          (collection) => ({ id: collection.id }),
        )}
      />
      <Card>
        <Card.Section>
          <FormLayout>
            <Stack vertical spacing="extraTight">
              <RadioButton
                label="All products"
                checked={formik.values.eligibilityType === 'AllProducts'}
                name="eligibilityType"
                id="AllProducts"
                onChange={handleChangeRadio}
              />
              <RadioButton
                label="Specific collections"
                name="eligibilityType"
                checked={
                  formik.values.eligibilityType === 'SelectedCollections'
                }
                id="SelectedCollections"
                onChange={handleChangeCollectionsRadio}
              />
            </Stack>
            {formik.values.eligibilityType === 'SelectedCollections' && (
              <PolarisTextField
                label="Browse Collections"
                labelHidden
                autoComplete="off"
                name="dummyInput"
                placeholder="Browse collections"
                error={formik.errors.eligibleCollections as string} // TODO type this properly
                connectedRight={
                  <Button onClick={() => handleOpenCollectionPicker()}>
                    Browse
                  </Button>
                }
                onChange={(value) => {
                  handleOpenCollectionPicker(value);
                }}
              />
            )}
          </FormLayout>
        </Card.Section>

        {formik.values.eligibilityType === 'SelectedCollections' &&
          formik.values.eligibleCollections.length > 0 && (
            <ResourceList
              items={formik.values.eligibleCollections}
              renderItem={(collection) => {
                const shortcutActions = [
                  {
                    content: 'Remove',
                    onClick: () => handleRemoveCollection(collection.id),
                  },
                ];
                const thumbnail = collection.image?.src ?? ImageMajor;
                const alt = collection.image?.altText ?? collection.title;

                return (
                  <ResourceItem
                    id={collection.id}
                    onClick={() => undefined}
                    shortcutActions={shortcutActions}
                    media={
                      <Thumbnail size="small" source={thumbnail} alt={alt} />
                    }
                    verticalAlignment="center"
                  >
                    <TextStyle variation="strong">{collection.title}</TextStyle>
                  </ResourceItem>
                );
              }}
            />
          )}
      </Card>
    </>
  );
}
