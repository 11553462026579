import {
  Card,
  FormLayout,
  DisplayText,
  Layout,
  List,
  Link,
  Page,
  Select,
  Banner,
  Spinner,
  Stack,
  PageActions,
  TextContainer,
} from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ThemeFragment,
  ThemeRole,
  useSetupCompleteMutation,
  useThemeIsCompatibleMutation,
} from '../queries';
import { useAppBridgeRedirect } from './AppBridgeRedirect';
import styles from './WelcomeContent.module.css';

interface WelcomeContentProps {
  themes: ThemeFragment[];
}

export default function WelcomeContent(props: WelcomeContentProps) {
  const { themes } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    themes.find((theme) => theme.role === ThemeRole.Main),
  );

  const [isThemeCompatible, setIsThemeCompatible] = useState<null | boolean>(
    null,
  );
  const [loading, setLoading] = useState(false);

  const { executeAppRedirect, executeAdminRedirect } = useAppBridgeRedirect();
  const { mutateAsync: completeSetup } = useSetupCompleteMutation();
  const { mutateAsync: checkIsThemeCompatible, isLoading } =
    useThemeIsCompatibleMutation();

  const handleNext = useCallback(async () => {
    setLoading(true);

    try {
      await completeSetup({});
    } catch (e) {
      console.warn(e);
    } finally {
      executeAppRedirect('/settings');
    }
  }, [completeSetup, executeAppRedirect]);

  const handleThemeChange = useCallback(
    async (selected: string) => {
      const theme = themes.find((theme) => theme.id === selected);
      if (!theme) {
        return;
      }

      setSelectedTheme(theme);

      if (theme.role === ThemeRole.Demo) {
        return;
      }

      try {
        const { themeIsCompatible } = await checkIsThemeCompatible({
          id: selected,
        });

        setIsThemeCompatible(themeIsCompatible);
      } catch (e) {
        console.error(e);
        setIsThemeCompatible(false);
      }
    },
    [checkIsThemeCompatible, themes],
  );

  useEffect(() => {
    if (selectedTheme) {
      handleThemeChange(selectedTheme.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banner = useMemo(() => {
    if (isLoading) {
      return (
        <Stack distribution="center" alignment="center">
          <Spinner accessibilityLabel="Validating theme" size="large" />
        </Stack>
      );
    } else if (selectedTheme?.role === ThemeRole.Demo) {
      return (
        <Banner
          title="Cannot check compatibility of a demo theme"
          status="warning"
        />
      );
    } else if (isThemeCompatible) {
      return (
        <Banner
          title="This theme is compatible with the product widget"
          status="success"
          action={{
            content: 'Add widget',
            onAction: () =>
              executeAdminRedirect(
                '/themes/current/editor?template=product',
                true,
              ),
          }}
          secondaryAction={{
            content: 'Instructions',
            url: 'https://together.satelcreative.com/pages/guides',
            external: true,
          }}
        />
      );
    } else if (isThemeCompatible === false) {
      return (
        <Banner
          title="This theme is not compatible with the product widget"
          status="critical"
          action={{
            content: 'Contact us',
            url: 'https://together.satelcreative.com/contact',
            external: true,
          }}
        >
          <p>Sorry! This theme is not natively supported. We can fix that!</p>
        </Banner>
      );
    }
  }, [executeAdminRedirect, isLoading, isThemeCompatible, selectedTheme?.role]);

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Banner status="info">
            <p>
              If your customer completes their purchase without an email address
              or with a phone number outside of Canada/USA, you will be notified
              via email automatically so that you can contact the customer and
              they don&apos;t miss out on their reward.
            </p>
          </Banner>
        </Layout.Section>
        <Layout.Section>
          <div className={styles.welcomeText}>
            <DisplayText size="large">Installation</DisplayText>
          </div>
        </Layout.Section>
      </Layout>
      <Layout>
        <Layout.AnnotatedSection
          title="Verify your theme"
          description={
            <>
              <p>
                Together is compatible with Shopify&apos;s Online Store 2.0
                themes. If you have an older theme,{' '}
                <Link
                  url="https://together.satelcreative.com/pages/contact"
                  external
                >
                  contact us
                </Link>{' '}
                for installation.
              </p>
              <br />
              <p>Please note that demo/trial themes cannot be validated.</p>
            </>
          }
        >
          <Card sectioned>
            <FormLayout>
              <Select
                label="Choose your theme"
                labelHidden
                options={themes.map((theme) => ({
                  label: theme.name,
                  value: theme.id,
                }))}
                value={selectedTheme?.id}
                onChange={handleThemeChange}
              />
              {banner}
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Quick Start"
          description={
            <>
              <Stack spacing="tight">
                <TextContainer>
                  <p>
                    Find out more about Together by visiting our site to see:
                  </p>
                </TextContainer>
                <List type="bullet">
                  <List.Item>
                    <Link
                      url="https://together.satelcreative.com/pages/guides"
                      external
                    >
                      Installation instructions
                    </Link>
                  </List.Item>
                  <List.Item>
                    Our{' '}
                    <Link url="https://together.satelcreative.com" external>
                      ethos
                    </Link>
                  </List.Item>
                </List>
              </Stack>
            </>
          }
        >
          <Card>
            <Card.Section>
              <Stack spacing="tight">
                <TextContainer>
                  <p>For a quick setup, follow this guideline:</p>
                </TextContainer>
                <List type="number">
                  <List.Item>
                    Configure your{' '}
                    <Link onClick={() => executeAppRedirect('/settings')}>
                      settings
                    </Link>
                  </List.Item>
                  <List.Item>
                    <Link
                      onClick={() =>
                        executeAdminRedirect(
                          '/themes/current/editor?template=product',
                          true,
                        )
                      }
                    >
                      Add the Together widget
                    </Link>{' '}
                    to your storefront&apos;s product page
                  </List.Item>
                  <List.Item>
                    Watch your{' '}
                    <Link onClick={() => executeAppRedirect('/')}>
                      dashboard
                    </Link>{' '}
                    for analytics
                  </List.Item>
                </List>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.Section>
          <PageActions
            primaryAction={{
              content: 'Next',
              onAction: handleNext,
              loading: loading,
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
