import { EmptyState } from '@shopify/polaris';
import MainForm from './MainForm';
import MainFormSkeleton from './MainFormSkeleton';
import { useSettingsQuery } from '../queries';
import { payloadToValues } from '../utils';

const Settings = () => {
  const { data, isLoading, error } = useSettingsQuery();

  if (error) {
    return (
      <EmptyState
        heading="Oops! Something went wrong."
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      />
    );
  }

  if (!data || isLoading) {
    return <MainFormSkeleton />;
  }

  return <MainForm initialValues={payloadToValues(data.settings)} />;
};

export default Settings;
