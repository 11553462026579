import { EmptyState, Layout, Page } from '@shopify/polaris';
import { useEffect } from 'react';
import {
  BillingStatus,
  useAnalyticsQuery,
  useSetupBillingMutation,
  useShopQuery,
} from '../queries';
import { useAppBridgeRedirect } from './AppBridgeRedirect';
import DashboardCard from './DashboardCard';
import DashboardSkeleton from './DashboardSkeleton';
import PageActions from './PageActions';

const Dashboard = () => {
  const { data, isLoading, error } = useAnalyticsQuery();
  const { data: shopQueryData, isLoading: isShopQueryLoading } = useShopQuery();
  const { executeAppRedirect, executeAdminRedirect } = useAppBridgeRedirect();
  const {
    mutate,
    isLoading: isSettingUpBilling,
    error: setupMutationError,
    isSuccess,
  } = useSetupBillingMutation({
    onSuccess: (data) => {
      if (!data?.billingSetup) {
        throw Error('Billing could not be set up');
      }

      const path = data.billingSetup.replace(
        /https:\/\/.*\.myshopify\.com\/admin/,
        '',
      );
      executeAdminRedirect(path);
    },
  });

  const loading = isLoading || isShopQueryLoading || isSettingUpBilling;

  useEffect(() => {
    if (
      !shopQueryData ||
      isSettingUpBilling ||
      isSuccess ||
      setupMutationError
    ) {
      return;
    }

    if (shopQueryData.shop.billingStatus === BillingStatus.Notset) {
      mutate({});
      return;
    }

    if (!shopQueryData.shop.setupComplete) {
      executeAppRedirect('/welcome');
    }
  }, [
    shopQueryData,
    mutate,
    executeAppRedirect,
    isSettingUpBilling,
    isSuccess,
    setupMutationError,
  ]);

  if (error || setupMutationError) {
    return (
      <EmptyState
        heading="Oops! Something went wrong."
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      />
    );
  }

  if (!data || loading) {
    return <DashboardSkeleton />;
  }

  return (
    <Page>
      <Layout>
        <Layout.Section oneHalf>
          <DashboardCard
            title="COUNT OF ORDERS CONVERTED"
            statistic={`${data.analytics.togetherOrders} Order${
              data.analytics.togetherOrders === 1 ? '' : 's'
            }`}
            subtext={`${data.analytics.totalOrders} order${
              data.analytics.totalOrders === 1 ? '' : 's'
            } since downloading Together`}
          />
        </Layout.Section>
        <Layout.Section oneHalf>
          <DashboardCard
            title="PERCENTAGE OF ORDERS CONVERTED"
            statistic={
              data.analytics.totalOrders === 0
                ? 'No Orders Placed'
                : `${Math.round(
                    (data.analytics.togetherOrders /
                      data.analytics.totalOrders) *
                      100,
                  )}% of Orders`
            }
          />
        </Layout.Section>
        <Layout.Section>
          <PageActions />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Dashboard;
