import { Collection as ResourcePickerCollection } from '@shopify/app-bridge/actions/ResourcePicker';
import { EligibleCollection, FormValues } from './components/MainForm';
import { Settings, SettingsUpdateInput } from './queries';

interface Edge<T> {
  node: T;
}
interface Connection<T> {
  edges: Edge<T>[];
}

export function connectionToNodes<T>(connection: Connection<T>): T[] {
  return connection.edges.map(({ node }) => node);
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function safeParseInt(str: string | null | undefined): number | null {
  if (str === '' || str === null || str === undefined) {
    return null;
  }

  const parsed = Number.parseInt(str, 10);

  if (Number.isNaN(parsed)) {
    return null;
  }

  return parsed;
}

export function collectionPickerSelectionToFormValues(
  selection: ResourcePickerCollection[],
): EligibleCollection[] {
  return selection.map((collection) => {
    const image = collection.image
      ? {
          src: collection.image.originalSrc,
          altText: collection.image.altText,
        }
      : null;
    return {
      id: collection.id,
      title: collection.title,
      image,
    };
  });
}

export function valuesToPayload(values: FormValues): SettingsUpdateInput {
  return {
    percentDiscount: safeParseInt(values.discount) ?? 0,
    eligibleOnAllProducts: values.eligibilityType === 'AllProducts',
    eligibleCollections: values.eligibleCollections.map((eligibleCollection) =>
      eligibleCollection.id.replace('gid://shopify/Collection/', ''),
    ),
    discountOnAllProducts: values.discountEligibilityType === 'AllProducts',
    discountCollections: values.discountCollections.map((discountCollection) =>
      discountCollection.id.replace('gid://shopify/Collection/', ''),
    ),
  };
}

function payloadToEligibleCollections(
  payload: Settings,
  type?: 'eligibleCollections' | 'discountCollections',
): EligibleCollection[] {
  const eligibility =
    type === 'discountCollections'
      ? payload.discountEligibility
      : payload.productEligibility;

  if (
    eligibility.__typename === 'SelectedCollections' &&
    eligibility.collections?.length
  ) {
    return eligibility.collections.map((collection) => {
      return {
        ...collection,
        id: `gid://shopify/Collection/${collection.legacyResourceId}`,
      };
    });
  }

  return [];
}

export function payloadToValues(payload: Settings): FormValues {
  return {
    discount: payload.percentDiscount.toString(),
    eligibilityType: payload.productEligibility.__typename ?? 'AllProducts',
    eligibleCollections: payloadToEligibleCollections(
      payload,
      'eligibleCollections',
    ),
    discountEligibilityType:
      payload.discountEligibility.__typename ?? 'AllProducts',
    discountCollections: payloadToEligibleCollections(
      payload,
      'discountCollections',
    ),
  };
}
