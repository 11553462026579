import { Page, Layout, Card, Stack, Banner, Link } from '@shopify/polaris';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { object, string, array } from 'yup';
import { useCallback, useState } from 'react';
import { TextField } from '@satel/formik-polaris';
import { Toast } from '@shopify/app-bridge-react';
import { payloadToValues, safeParseInt, valuesToPayload } from '../utils';
import { ContextualSaveBar } from './ContextualSaveBar';
import { ProductEligibility } from './ProductEligibility';
import { DiscountEligibility } from './DiscountEligibility';
import { Image, SettingsQuery, useSettingsUpdateMutation } from '../queries';
import { useQueryClient } from 'react-query';
import { useAppBridgeRedirect } from './AppBridgeRedirect';
import PageActions from './PageActions';

const SCHEMA = object({
  discount: string()
    .test(
      'percentage',
      'Discount must be a valid percentage between 1 and 100',
      (value) => {
        const val = safeParseInt(value);
        if (val === null) {
          return false;
        }

        return val >= 1 && val <= 100;
      },
    )
    .required(),
  eligibilityType: string()
    .oneOf(['AllProducts', 'SelectedCollections'])
    .required(),
  eligibleCollections: array().when('eligibilityType', {
    is: 'SelectedCollections',
    then: array().min(1, 'Please select at least one collection').required(),
    otherwise: array(),
  }),
  discountEligibilityType: string()
    .oneOf(['AllProducts', 'SelectedCollections'])
    .required(),
  discountCollections: array().when('discountEligibilityType', {
    is: 'SelectedCollections',
    then: array().min(1, 'Please select at least one collection').required(),
    otherwise: array(),
  }),
});

interface ToastState {
  content: string;
  error?: boolean;
  duration?: number;
}

interface ToastOptions {
  error?: boolean;
  duration?: number;
}

export interface EligibleCollection {
  id: string;
  image?: Image | null;
  title: string;
}

export type EligibilityType = 'AllProducts' | 'SelectedCollections';

export interface FormValues {
  discount: string;
  eligibilityType: EligibilityType;
  eligibleCollections: EligibleCollection[];
  discountEligibilityType: EligibilityType;
  discountCollections: EligibleCollection[];
}

interface MainFormProps {
  initialValues: FormValues;
}

export default function MainForm(props: MainFormProps) {
  const { initialValues } = props;

  // App Bridge Redirect
  const { executeAdminRedirect, executeAppRedirect } = useAppBridgeRedirect();

  // React Query
  const queryClient = useQueryClient();

  const { mutateAsync: updateSettings } = useSettingsUpdateMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<SettingsQuery>(['Settings'], (old) => ({
        ...old,
        settings: data.settingsUpdate,
      }));
    },
  });

  // Toast
  const [toast, setToast] = useState<ToastState | null>(null);

  const handleDismiss = useCallback(() => {
    setToast(null);
  }, []);

  const showToast = useCallback(
    (content: string, options: ToastOptions = {}) => {
      setToast({ content, ...options });
    },
    [],
  );

  // Formik
  const handleSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { settingsUpdate } = await updateSettings({
          input: valuesToPayload(values),
        });

        helpers.resetForm({
          values: payloadToValues(settingsUpdate),
        });

        showToast('Settings updated', { duration: 2000 });
      } catch (e) {
        showToast('Error saving', { duration: 2000, error: true });
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    },
    [showToast, updateSettings],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: SCHEMA,
  });

  return (
    <Page>
      <FormikProvider value={formik}>
        {formik.dirty && (
          <>
            <ContextualSaveBar
              saveAction={{
                loading: formik.isSubmitting,
                onAction: () => {
                  formik.submitForm();
                },
              }}
              discardAction={{
                disabled: formik.isSubmitting,
                onAction: () => {
                  formik.resetForm();
                },
                discardConfirmationModal: true,
              }}
            />
          </>
        )}
        <Form>
          {toast && <Toast {...toast} onDismiss={handleDismiss} />}

          <Layout>
            <Layout.Section>
              <Banner
                title="Installation"
                status="info"
                action={{
                  content: 'Add widget',
                  onAction: () =>
                    executeAdminRedirect(
                      '/themes/current/editor?template=product',
                      true,
                    ),
                }}
                secondaryAction={{
                  content: 'Instructions',
                  url: 'https://together.satelcreative.com/pages/guides',
                  external: true,
                }}
              >
                <p>
                  Please follow the instructions to easily add the product
                  widget to your theme. Online 2.0 theme required.{' '}
                  <Link onClick={() => executeAppRedirect('/welcome')}>
                    Verify here
                  </Link>
                </p>
              </Banner>
            </Layout.Section>
            <Layout.AnnotatedSection
              title="Eligible Products"
              description={
                <>
                  Choose which products are eligible for upgrade to final sale.
                  To choose specific products, create a collection in your
                  Shopify Admin and add any eligible products.
                </>
              }
            >
              <ProductEligibility />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Discount Code"
              description={
                <>
                  A unique discount code will be created when the upgraded order
                  is fulfilled.
                  <br />
                  <br />
                  Be mindful of your profit margins when setting the discount
                  percentage, but make it tempting to come back!
                </>
              }
            >
              <Card>
                <Card.Section>
                  <Stack>
                    <Stack.Item>
                      <TextField
                        name="discount"
                        label="Percentage discount"
                        type="number"
                        suffix="%"
                        disabled={formik.isSubmitting}
                        autoComplete="off"
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
                <DiscountEligibility />
              </Card>
            </Layout.AnnotatedSection>
            <Layout.Section>
              <PageActions
                button={{
                  primary: true,
                  disabled: !formik.dirty,
                  loading: formik.isSubmitting,
                  onClick: () => formik.submitForm(),
                }}
              />
            </Layout.Section>
          </Layout>
        </Form>
      </FormikProvider>
    </Page>
  );
}
